import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { FunctionComponent } from 'react';
import Image from '../types/Image';
import { isMobileSafari } from '../utils/browser';

interface FooterData {
  footerJson: {
    about: string;
    credits: string[];
    email: string;
    experiences: {
      company: string;
      title: string;
      years: string;
    }[];
    image: Image;
    selectedClients: string[];
    socialLinks: {
      name: string;
      url: string;
    }[];
  };
}

const Footer: FunctionComponent = () => {
  const { footerJson: data }: FooterData = useStaticQuery(graphql`
    query {
      footerJson {
        about
        credits
        email
        experiences {
          company
          title
          years
        }
        image {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        selectedClients
        socialLinks {
          name
          url
        }
      }
    }
  `);

  return (
    <div>
      <div className="mt-16 sm:mt-20 md:mt-32 flex flex-wrap sm:-mx-2 text-sm">
        <div className="w-full sm:w-1/3 lg:w-1/4 sm:px-2">
          <span className="font-bold">ANTOINE BARRÈS</span>
          <p className="mt-3 lg:pl-4 md:pr-8 whitespace-pre-line">
            {data.about}
          </p>
        </div>
        <div className="w-full sm:w-1/3 lg:w-1/4 mt-8 sm:mt-0 sm:px-2">
          <div>
            <span className="font-bold">CONTACT</span>
            <div className="mt-3 lg:pl-4">
              <a
                className="inline-block py-1 md:py-0 px-2 md:px-1 rounded md:rounded-sm border border-black uppercase text-sm focus:outline-none focus:shadow-outline md:hover:bg-black md:hover:text-white"
                href={`mailto:${data.email}`}
              >
                {data.email}
              </a>
              <div>
                {data.socialLinks.map(link => (
                  <div key={link.url}>
                    <a
                      className="inline-block mt-2 py-1 md:py-0 px-2 md:px-1 rounded md:rounded-sm border border-black uppercase text-sm focus:outline-none focus:shadow-outline md:hover:bg-black md:hover:text-white"
                      href={link.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {link.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-8">
            <span className="font-bold">EXPERIENCES</span>
            <div className="mt-3 lg:pl-4 pr-8">
              {data.experiences.map((experience, index) => (
                <div key={experience.title}>
                  <p className="uppercase">{experience.company}</p>
                  <p className="uppercase">{experience.title}</p>
                  <p className="uppercase">{experience.years}</p>
                  {index !== data.experiences.length - 1 && <br />}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/3 lg:w-1/4 mt-8 sm:mt-0 sm:px-2">
          <div>
            <span className="font-bold">SELECTED CLIENTS</span>
            <div className="mt-3 lg:pl-4 pr-8">
              {data.selectedClients.map(client => (
                <p className="uppercase" key={client}>
                  {client}
                </p>
              ))}
            </div>
          </div>
          <div className="mt-8">
            <span className="font-bold">THANKS TO</span>
            <div className="mt-3 lg:pl-4 pr-8">
              {data.credits.map(credit => (
                <p className="uppercase" key={credit}>
                  {credit}
                </p>
              ))}
            </div>
          </div>
          <div className="block lg:hidden mt-8">
            <Img
              backgroundColor={isMobileSafari() ? undefined : '#e5e5e5'}
              fadeIn={false}
              fluid={data.image.childImageSharp.fluid}
            />
          </div>
        </div>
        <div className="hidden lg:block w-1/4 px-2">
          <Img
            backgroundColor={isMobileSafari() ? undefined : '#e5e5e5'}
            fadeIn={false}
            fluid={data.image.childImageSharp.fluid}
          />
        </div>
      </div>
      <div className="mt-16 sm:mt-20 md:mt-32 flex items-center">
        <div className="w-2/3 lg:w-3/4">
          <p className="text-sm">© {new Date().getFullYear()}</p>
        </div>
        <div className="w-1/3 lg:w-1/4 flex items-center justify-end sm:justify-start">
          <a
            className="px-1 text-sm rounded-sm focus:outline-none focus:shadow-outline"
            href="/#"
          >
            TOP ↑
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
