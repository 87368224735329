import { graphql, useStaticQuery } from 'gatsby';
import React, { FunctionComponent } from 'react';
import Helmet from 'react-helmet';

interface SEOData {
  site: {
    siteMetadata: {
      author: string;
      description: string;
      title: string;
    };
  };
}

const SEO: FunctionComponent = () => {
  const { site }: SEOData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            title
          }
        }
      }
    `,
  );

  const { author, description, title } = site.siteMetadata;

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang: 'en',
      }}
      title={site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: 'https://antoinebarres.netlify.com/images/preview.jpg',
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: 'https://antoinebarres.netlify.com/images/preview.jpg',
        },
        {
          name: 'twitter:image:alt',
          content: title,
        },
        {
          name: 'p:domain_verify',
          content: 'e88fe8b26561b2f09be1b4694aa970c9',
        },
      ]}
    >
      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "Person",
          "email": "mail@antoinebarres.com",
          "image": "https://antoinebarres.netlify.com/images/profil_picture.jpg",
          "jobTitle": "Art Director & Product Designer",
          "name": "Antoine Barrès",
          "birthDate": "1992-09-14",
          "gender": "male",
          "nationality": "French",
          "url": "https://www.antoinebarres.com/",
          "sameAs" : [
            "https://www.instagram.com/antoine.barres/",
            "https://www.behance.net/antoinebarres",
            "https://dribbble.com/antoinebarres",
            "https://www.linkedin.com/in/antoinebarres/"
          ]
    }`}
      </script>
    </Helmet>
  );
};

export default SEO;
