import clsx from 'clsx';
// eslint-disable-next-line import/no-unresolved
import snakeCase from 'lodash.snakecase';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FunctionComponent, useState, useEffect } from 'react';
import SlideDown from 'react-slidedown';

interface HeaderData {
  allProjectsJson: {
    nodes: {
      order: number;
      title: string;
    }[];
  };
  headerJson: {
    email: string;
    recognitions: string[];
    socialLinks: {
      name: string;
      url: string;
    }[];
    title: string;
  };
}

const Header: FunctionComponent = () => {
  const {
    allProjectsJson: projectsData,
    headerJson: data,
  }: HeaderData = useStaticQuery(graphql`
    query {
      headerJson {
        email
        socialLinks {
          name
          url
        }
        title
      }
      allProjectsJson {
        nodes {
          order
          title
        }
      }
    }
  `);

  const projects = projectsData.nodes.sort((a, b) =>
    a.order > b.order ? 1 : -1,
  );

  const [showFirstName, setShowFirstName] = useState(false);
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  useEffect(() => {
    const localStorageFlag = localStorage.getItem('showFirstName');

    if (localStorageFlag && localStorageFlag === 'true') {
      setShowFirstName(true);
      localStorage.setItem('showFirstName', 'false');
      return;
    }

    localStorage.setItem('showFirstName', 'true');
  }, []);

  return (
    <header>
      <div className="flex justify-between items-center md:-mx-2">
        <div className="md:w-2/3 lg:w-3/4 md:px-2">
          <a className="focus:outline-none" href="/">
            <h1 className="inline-block font-ginto text-4xl xs:text-5xl sm:text-6xl md:text-7xl tracking-tight leading-none">
              {showFirstName ? 'Antoine B.' : 'A. Barrès'}
            </h1>
          </a>
        </div>
        <div className="hidden md:block w-1/3 lg:w-1/4 px-2 font-bold text-sm uppercase">
          <h2>{data.title}</h2>
          <div>
            <a
              className="focus:outline-none focus:underline hover:underline"
              href={`mailto:${data.email}`}
            >
              {data.email}
            </a>
          </div>
          <div>
            {data.socialLinks.map((link, index) => (
              <span key={link.url}>
                {index > 0 && <span className="px-2">•</span>}
                <span key={link.url}>
                  <a
                    className="focus:outline-none focus:underline hover:underline"
                    href={link.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {link.name}
                  </a>
                </span>
              </span>
            ))}
          </div>
        </div>
        <div className="block md:hidden">
          <button
            className={clsx(
              'min-w-17 md:min-w-0 py-2 rounded border border-black text-sm leading-none focus:outline-none focus:shadow-outline',
              isMenuExpanded && 'bg-black text-white',
            )}
            onClick={(): void => setIsMenuExpanded(!isMenuExpanded)}
            type="button"
          >
            {isMenuExpanded ? 'CLOSE' : 'INFO'}
          </button>
        </div>
      </div>
      <SlideDown
        className={clsx(
          'block md:hidden project-slidedown',
          isMenuExpanded && 'project-slidedown-expanded',
        )}
      >
        {isMenuExpanded && (
          <div className="pt-16 md:pt-32 text-sm">
            <h2 className="font-bold uppercase">{data.title}</h2>
            <div className="mt-4">
              <a
                className="inline-block py-1 px-2 rounded border border-black uppercase text-sm focus:outline-none focus:shadow-outline"
                href={`mailto:${data.email}`}
              >
                {data.email}
              </a>
            </div>
            <div className="mt-3">
              {data.socialLinks.map((link, index) => (
                <span key={link.url}>
                  <a
                    className={clsx(
                      'inline-block py-1 px-2 rounded border border-black uppercase text-sm focus:outline-none focus:shadow-outline',
                      index < data.socialLinks.length - 1 && 'mr-2',
                    )}
                    href={link.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {link.name}
                  </a>
                </span>
              ))}
            </div>
            <div className="mt-8 leading-tight">
              <span className="font-bold text-sm">SELECTED WORK</span>
              <span className="px-2">•</span>
              {projects.map((project, index) => (
                <span key={project.title}>
                  <a
                    className="text-sm uppercase focus:outline-none focus:underline hover:underline"
                    href={`/#${snakeCase(project.title)}`}
                  >
                    {project.title}
                  </a>
                  {index === projects.length - 1 ? '' : ', '}
                </span>
              ))}
            </div>
            {data.recognitions && data.recognitions.length > 0 && (
              <div className="leading-tight">
                <span className="font-bold text-sm">RECOGNITIONS</span>
                <span className="px-2">•</span>
                {data.recognitions.map((recognition, index) => (
                  <span className="text-sm uppercase" key={recognition}>
                    {recognition}
                    {index === data.recognitions.length - 1 ? '' : ', '}
                  </span>
                ))}
              </div>
            )}
          </div>
        )}
      </SlideDown>
      <div className="hidden md:block mt-10 md:mt-32 leading-tight">
        <span className="font-bold text-sm">SELECTED WORK</span>
        <span className="px-2">•</span>
        {projects.map((project, index) => (
          <span key={project.title}>
            <a
              className="text-sm uppercase focus:outline-none focus:underline hover:underline"
              href={`/#${snakeCase(project.title)}`}
            >
              {project.title}
            </a>
            {index === projects.length - 1 ? '' : ', '}
          </span>
        ))}
      </div>
      {data.recognitions && data.recognitions.length > 0 && (
        <div className="hidden md:block leading-tight">
          <span className="font-bold text-sm">RECOGNITIONS</span>
          <span className="px-2">•</span>
          {data.recognitions.map((recognition, index) => (
            <span className="text-sm uppercase" key={recognition}>
              {recognition}
              {index === data.recognitions.length - 1 ? '' : ', '}
            </span>
          ))}
        </div>
      )}
    </header>
  );
};

export default Header;
