import clsx from 'clsx';
import Img from 'gatsby-image';
// eslint-disable-next-line import/no-unresolved
import snakeCase from 'lodash.snakecase';
import React, { FunctionComponent, useState } from 'react';
import InViewMonitor from 'react-inview-monitor';
import { SlideDown } from 'react-slidedown';
import Image from '../types/Image';
import { isMobileSafari } from '../utils/browser';

interface ProjectProps {
  credits: {
    title: string;
    name: string;
  }[];
  description: string;
  mainImage: Image;
  name: string;
  onImagePress: (imageId: string) => void;
  secondaryImages: Image[];
  title: string;
}

const ButtonImage: FunctionComponent<{
  image: Image;
  onImagePress: (imageId: string) => void;
}> = ({ image, onImagePress }) => (
  <button
    aria-label="Open Gallery"
    className="w-full flex focus:outline-none focus:shadow-outline cursor-zoom-in"
    onClick={(): void => onImagePress(image.id)}
    type="button"
  >
    <Img
      backgroundColor={isMobileSafari() ? undefined : '#e5e5e5'}
      className="flex-1"
      fluid={image.childImageSharp.fluid}
    />
  </button>
);

const Project: FunctionComponent<ProjectProps> = ({
  credits,
  description,
  mainImage,
  name,
  onImagePress,
  secondaryImages,
  title,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <InViewMonitor
      classNameInView="animated"
      classNameNotInView="animated animated-hidden"
      intoViewMargin="0%"
      toggleClassNameOnInView
    >
      <div className="mt-16 sm:mt-20 md:mt-32" id={snakeCase(title)}>
        <div className="flex items-center justify-between md:justify-start mb-4">
          <div className="project-name-container">
            <span className="font-bold text-sm uppercase">{title}</span>
            <span className="px-2">•</span>
            <span className="text-sm uppercase">{name}</span>
          </div>
          <button
            className={clsx(
              'min-w-17 md:min-w-0 ml-3 py-2 md:p-1 rounded md:rounded-sm border border-black text-sm leading-none focus:outline-none focus:shadow-outline md:hover:bg-black md:hover:text-white',
              isExpanded && 'bg-black text-white md:bg-white md:text-black',
            )}
            onClick={(): void => setIsExpanded(!isExpanded)}
            type="button"
          >
            {isExpanded ? 'CLOSE' : 'MORE'}
          </button>
        </div>
        <SlideDown
          className={clsx(
            'w-full lg:w-1/2 lg:pr-2 project-slidedown',
            isExpanded && 'project-slidedown-expanded',
          )}
        >
          {isExpanded && (
            <div className="text-sm pb-4">
              <div className={credits.length > 0 ? '' : 'mb-4'}>
                <p className="whitespace-pre-line">{description}</p>
              </div>
              {credits.length > 0 && (
                <>
                  <br />
                  <div className="flex">
                    <ul className="pr-8">
                      {credits.map(({ title: creditTitle }) => (
                        <li key={creditTitle}>{creditTitle}:</li>
                      ))}
                    </ul>
                    <ul className="pl-8">
                      {credits.map(({ name: creditName }) => (
                        <li key={creditName}>{creditName}</li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          )}
        </SlideDown>
        <div className="flex flex-wrap -mx-2">
          <div className="w-full sm:w-2/3 lg:w-1/2 px-2">
            <ButtonImage image={mainImage} onImagePress={onImagePress} />
          </div>

          {/* Tablet */}
          <div className="hidden sm:flex flex-wrap lg:hidden w-1/3 px-2">
            <div className="w-full pb-2">
              <ButtonImage
                image={secondaryImages[0]}
                onImagePress={onImagePress}
              />
            </div>
            <div className="w-full pt-2">
              <ButtonImage
                image={secondaryImages[1]}
                onImagePress={onImagePress}
              />
            </div>
          </div>

          {/* Desktop */}
          <div className="hidden lg:flex w-1/2 flex-wrap px-2">
            <div className="w-full flex flex-wrap pb-2">
              <div className="w-1/2 pr-2">
                <ButtonImage
                  image={secondaryImages[0]}
                  onImagePress={onImagePress}
                />
              </div>
              <div className="w-1/2 pl-2">
                <ButtonImage
                  image={secondaryImages[1]}
                  onImagePress={onImagePress}
                />
              </div>
            </div>
            <div className="w-full flex flex-wrap pt-2">
              <div className="w-1/2 pr-2">
                <ButtonImage
                  image={secondaryImages[2]}
                  onImagePress={onImagePress}
                />
              </div>
              {secondaryImages.length > 3 && (
                <div className="w-1/2 pl-2">
                  <ButtonImage
                    image={secondaryImages[3]}
                    onImagePress={onImagePress}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Mobile */}
        <div className="flex sm:hidden pt-4 -mx-2">
          <div className="w-1/2 px-2">
            <ButtonImage
              image={secondaryImages[0]}
              onImagePress={onImagePress}
            />
          </div>
          <div className="w-1/2 px-2">
            <ButtonImage
              image={secondaryImages[1]}
              onImagePress={onImagePress}
            />
          </div>
        </div>
        <div className="flex sm:hidden pt-4 -mx-2">
          <div className="w-1/2 px-2">
            <ButtonImage
              image={secondaryImages[2]}
              onImagePress={onImagePress}
            />
          </div>
          {secondaryImages.length > 3 && (
            <div className="w-1/2 px-2">
              <ButtonImage
                image={secondaryImages[3]}
                onImagePress={onImagePress}
              />
            </div>
          )}
        </div>
        {secondaryImages.length > 4 && (
          <div className="flex sm:hidden pt-4 -mx-2">
            <div className="w-1/2 px-2">
              <ButtonImage
                image={secondaryImages[4]}
                onImagePress={onImagePress}
              />
            </div>
            {secondaryImages.length > 5 && (
              <div className="w-1/2 px-2">
                <ButtonImage
                  image={secondaryImages[5]}
                  onImagePress={onImagePress}
                />
              </div>
            )}
          </div>
        )}
        {secondaryImages.length > 6 && (
          <div className="flex sm:hidden pt-4 -mx-2">
            <div className="w-1/2 px-2">
              <ButtonImage
                image={secondaryImages[6]}
                onImagePress={onImagePress}
              />
            </div>
            {secondaryImages.length > 7 && (
              <div className="w-1/2 px-2">
                <ButtonImage
                  image={secondaryImages[7]}
                  onImagePress={onImagePress}
                />
              </div>
            )}
          </div>
        )}

        {/* Tablet */}
        <div className="hidden sm:flex lg:hidden pt-4 -mx-2">
          <div className="w-1/3 px-2">
            <ButtonImage
              image={secondaryImages[2]}
              onImagePress={onImagePress}
            />
          </div>
          {secondaryImages.length > 3 && (
            <div className="w-1/3 px-2">
              <ButtonImage
                image={secondaryImages[3]}
                onImagePress={onImagePress}
              />
            </div>
          )}
          {secondaryImages.length > 4 && (
            <div className="w-1/3 px-2">
              <ButtonImage
                image={secondaryImages[4]}
                onImagePress={onImagePress}
              />
            </div>
          )}
        </div>
        {secondaryImages.length > 5 && (
          <div className="hidden sm:flex lg:hidden pt-4 -mx-2">
            <div className="w-1/3 px-2">
              <ButtonImage
                image={secondaryImages[5]}
                onImagePress={onImagePress}
              />
            </div>
            {secondaryImages.length > 6 && (
              <div className="w-1/3 px-2">
                <ButtonImage
                  image={secondaryImages[6]}
                  onImagePress={onImagePress}
                />
              </div>
            )}
            {secondaryImages.length > 7 && (
              <div className="w-1/3 px-2">
                <ButtonImage
                  image={secondaryImages[7]}
                  onImagePress={onImagePress}
                />
              </div>
            )}
          </div>
        )}

        {/* Desktop */}
        {secondaryImages.length > 4 && (
          <div className="hidden lg:flex pt-4 -mx-2">
            <div className="w-1/4 px-2">
              <ButtonImage
                image={secondaryImages[4]}
                onImagePress={onImagePress}
              />
            </div>
            {secondaryImages.length > 5 && (
              <div className="w-1/4 px-2">
                <ButtonImage
                  image={secondaryImages[5]}
                  onImagePress={onImagePress}
                />
              </div>
            )}
            {secondaryImages.length > 6 && (
              <>
                <div className="w-1/4 px-2">
                  <ButtonImage
                    image={secondaryImages[6]}
                    onImagePress={onImagePress}
                  />
                </div>
                {secondaryImages.length > 7 && (
                  <div className="w-1/4 px-2">
                    <ButtonImage
                      image={secondaryImages[7]}
                      onImagePress={onImagePress}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </InViewMonitor>
  );
};

export default Project;
