import { graphql, useStaticQuery } from 'gatsby';
import React, { FunctionComponent, useState } from 'react';
import '../assets/styles/index.css';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import Header from '../components/Header';
import Project from '../components/Project';
import SEO from '../components/SEO';
import Image from '../types/Image';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line
  require('smooth-scroll')('a[href*="#"]', {
    speedAsDuration: true,
  });
}

const handleFirstTab = (event: KeyboardEvent): void => {
  if (event.keyCode !== 9) {
    return;
  }

  document.body.classList.add('show-outline');

  if (typeof window !== 'undefined') {
    window.removeEventListener('keydown', handleFirstTab);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    window.addEventListener('mousedown', handleMouseDownOnce);
  }
};

const handleMouseDownOnce = (): void => {
  document.body.classList.remove('show-outline');

  if (typeof window !== 'undefined') {
    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
  }
};

if (typeof window !== 'undefined') {
  window.addEventListener('keydown', handleFirstTab);
}

interface ProjectsData {
  allProjectsJson: {
    nodes: {
      credits: {
        title: string;
        name: string;
      }[];
      description: string;
      id: string;
      mainImage: Image;
      name: string;
      order: number;
      secondaryImages: Image[];
      title: string;
    }[];
  };
}

const IndexPage: FunctionComponent = () => {
  const {
    allProjectsJson: projectsData,
  }: ProjectsData = useStaticQuery(graphql`
    query {
      allProjectsJson {
        nodes {
          credits {
            title
            name
          }
          description
          id
          mainImage {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            id
          }
          secondaryImages {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            id
          }
          name
          order
          title
        }
      }
    }
  `);

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryImageIndex, setGalleryImageIndex] = useState<number>(0);

  const projects = projectsData.nodes.sort((a, b) =>
    a.order > b.order ? 1 : -1,
  );

  const images = projects
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .reduce(
      (acc, project) => {
        return acc.concat([project.mainImage, ...project.secondaryImages]);
      },
      [] as Image[],
    );

  return (
    <>
      <SEO />
      <div className="font-sans mx-auto p-4" id="main">
        <Header />
        <Gallery
          currentImageIndex={galleryImageIndex}
          images={images}
          isOpen={isGalleryOpen}
          onRequestClose={(): void => setIsGalleryOpen(false)}
          setCurrentImageIndex={(index): void => setGalleryImageIndex(index)}
        />
        {projects.map(project => (
          <Project
            credits={project.credits}
            description={project.description}
            key={project.id}
            mainImage={project.mainImage}
            name={project.name}
            onImagePress={(imageId: string): void => {
              const imageIndex = images.findIndex(i => i.id === imageId);

              setGalleryImageIndex(imageIndex);
              setIsGalleryOpen(true);
            }}
            secondaryImages={project.secondaryImages}
            title={project.title}
          />
        ))}
        <Footer />
      </div>
    </>
  );
};

export default IndexPage;
