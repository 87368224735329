/* eslint-disable import/prefer-default-export */

export const isMobileSafari = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  const { userAgent } = window.navigator;
  const iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
  const webkit = !!userAgent.match(/WebKit/i);

  return iOS && webkit && !userAgent.match(/CriOS/i);
};
