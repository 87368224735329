// eslint-disable-next-line import/no-unresolved
import Img from 'gatsby-image/withIEPolyfill';
import React, {
  FunctionComponent,
  KeyboardEvent,
  useRef,
  MouseEvent,
} from 'react';
import Modal from 'react-modal';
import Image from '../types/Image';
import { isMobileSafari } from '../utils/browser';

Modal.setAppElement('#___gatsby');

interface GalleryProps {
  currentImageIndex: number;
  images: Image[];
  isOpen: boolean;
  onRequestClose: () => void;
  setCurrentImageIndex: (index: number) => void;
}

const getNextIndex = (currentIndex: number, arrayLength: number): number => {
  const nextIndex = currentIndex + 1;

  return nextIndex > arrayLength - 1 ? 0 : nextIndex;
};

const getPreviousIndex = (
  currentIndex: number,
  arrayLength: number,
): number => {
  const previousIndex = currentIndex - 1;

  return previousIndex < 0 ? arrayLength - 1 : previousIndex;
};

const Gallery: FunctionComponent<GalleryProps> = ({
  currentImageIndex,
  images,
  isOpen,
  onRequestClose,
  setCurrentImageIndex,
}) => {
  const imageEl = useRef<HTMLDivElement>(null);

  const goToNextImage = (): void => {
    const newIndex = getNextIndex(currentImageIndex, images.length);
    setCurrentImageIndex(newIndex);
  };

  const goToPreviousImage = (): void => {
    const newIndex = getPreviousIndex(currentImageIndex, images.length);
    setCurrentImageIndex(newIndex);
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={(): void => {
        if (!imageEl || !imageEl.current) {
          return;
        }

        imageEl.current.focus();
      }}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          bottom: 0,
          left: 0,
          position: 'fixed',
          right: 0,
          top: 0,
        },
        content: {
          background: '#e5e5e5',
          border: 'none',
          borderRadius: 0,
          bottom: 0,
          left: 0,
          outline: 'none',
          overflow: 'auto',
          padding: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          WebkitOverflowScrolling: 'touch',
        },
      }}
      contentLabel="Gallery"
    >
      <div
        className="relative h-full flex items-center justify-center"
        onClick={(event: MouseEvent<HTMLDivElement>): void => {
          if (event.pageX > window.innerWidth / 2) {
            goToNextImage();
          } else {
            goToPreviousImage();
          }
        }}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>): void => {
          if (event.keyCode === 37) {
            goToPreviousImage();
            return;
          }

          if (event.keyCode === 39) {
            goToNextImage();
          }
        }}
        ref={imageEl}
        role="button"
        tabIndex={0}
      >
        <div className="absolute top-0 left-0 z-20 h-full w-1/2 cursor-w-resize" />
        <div className="absolute top-0 right-0 z-20 h-full w-1/2 cursor-e-resize" />
        <div className="absolute pt-4 pl-4 top-0 left-0 z-30">
          <button
            className="inline-block font-ginto text-4xl xs:text-5xl sm:text-6xl md:text-7xl tracking-tight leading-none focus:outline-none"
            onClick={onRequestClose}
            type="button"
          >
            <span>Back</span>
          </button>
        </div>
        <div className="absolute pb-4 pl-4 bottom-0 left-0 z-10">
          <p className="font-sans text-sm">
            {(currentImageIndex + 1).toString().padStart(2, '0')} /{' '}
            {images.length}
          </p>
        </div>
        <Img
          className="hidden"
          fluid={
            images[getPreviousIndex(currentImageIndex, images.length)]
              .childImageSharp.fluid
          }
          loading="eager"
        />
        <Img
          backgroundColor={isMobileSafari() ? undefined : '#e5e5e5'}
          className="w-full h-full"
          fadeIn={false}
          fluid={images[currentImageIndex].childImageSharp.fluid}
          objectFit="contain"
        />
        <Img
          className="hidden"
          fluid={
            images[getNextIndex(currentImageIndex, images.length)]
              .childImageSharp.fluid
          }
          loading="eager"
        />
      </div>
    </Modal>
  );
};

export default Gallery;
